
import { computed, ref, watch, onMounted } from "vue";
import AddressTypesSelect from "@/components/catalogs-select/AddressTypesSelect.vue";
import LocationsSelect from "@/components/catalogs-select/LocationsSelect.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";

export default {
  name: "AddressSection",
  components: {
    TableBasicSelect,
    AddressTypesSelect,
    LocationsSelect,
    Form,
    Field,
    ErrorMessage,
  },

  props: {
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const location = ref();
    // activities log store
    const recently_activities = computed(
      () => store.getters.RecentlyActivities
    );
    const submitAddressButton = ref<HTMLElement | null>(null);
    const addressesTableData = computed(() => store.getters.AddressOffice);
    const office = computed(() => store.getters.Office);
    const editAddress = computed(() => store.getters.AEdit);

    const header = ref([
      { column: "istreet", key: "street" },
      { column: "inumber", key: "number" },
      { column: "ilocationcode", key: "location.full_code" },
      { column: "ilocationname", key: "location.full_name" },
      { column: "izipcode", key: "zip_code" },
    ]);
    const isLoading = ref(false);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const form = ref({
      id: undefined,
      company_id: undefined,
      office_id: undefined,
      entity_id: undefined,
      contact_id: undefined,
      address_types: undefined,
      street: "",
      number: "",
      floor_number: "",
      zip_code: "",
      door: "",
      location_id: undefined,
      latitude: "",
      longitude: "",
    });

    watch(
      () => form,
      (first, second) => {
        if (first) {
          if (first.value.id) {
            /*recently_activities.value.forEach((x) => {
              if (x.id === `office_${route.params.id}`) {
                x.data.addresses.forEach((y) => {
                  if (y.id === first.value.id) {
                    y = first.value;
                    store.commit("addDataActivity", {
                      id: x.id,
                      data: x.data,
                      name: x.name,
                    });
                  }
                });
              }
            });*/
          } else {
            recently_activities.value.forEach((x) => {
              if (x.id === `office_${route.params.id}`) {
                x.data.addresses = first.value;
                store.commit("addDataActivity", {
                  id: x.id,
                  data: x.data,
                  name: x.name,
                });
              }
            });
          }
        }
      },
      { deep: true }
    );

    const isStreetRequired = (value) => {
      if (!value) {
        return t("rstreet");
      }
      return true;
    };

    const onNewClick = () => {
      form.value.office_id = office.value.office_id;
      store.commit("setAEdit", !editAddress.value);
    };

    /*watch(
      () => office.value,
      (first) => {
        form.value.company_id = first[0].company_id;
        form.value.office_id = first[0].id;
      }
    );*/

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/address/${param1}`).then(({ data }) => {
          form.value.id = data.id;
          form.value.office_id = data.office_id;
          form.value.company_id = data.company_id;
          form.value.entity_id = data.entity_id;
          form.value.contact_id = data.contact_id;
          form.value.address_types = data.address_types.map((x) => x.id);
          form.value.street = data.street;
          form.value.number = data.number;
          form.value.floor_number = data.floor_number;
          form.value.door = data.door;
          form.value.location_id = data.location_id;
          form.value.zip_code = data.zip_code;
          form.value.latitude = data.latitude;
          form.value.longitude = data.longitude;
          location.value = data.location;
          console.log(data);
          store.commit("setAEdit", true);
          store.commit("setLoadingStatus", false);
        });
      }
      if (param2 == "DUPLICATE") {
        store.commit("setLoadingStatus", true);
        const item = addressesTableData.value.filter((x) => x.id === param3);
        let payload = {
          company_id: item[0].company_id,
          office_id: office.value.id,
          entity_id: item[0].entity_id,
          contact_id: item[0].contact_id,
          address_types: item[0].address_types.map((x) => x.id),
          street: item[0].street,
          number: item[0].number,
          floor_number: item[0].floor_number,
          door: item[0].door,
          location_id: item[0].location_id,
          zip_code: item[0].zip_code,
          latitude: item[0].latitude,
          longitude: item[0].longitude,
        };
        ApiService.post("/api/address", payload)
          .then(({ data }) => {
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
            store.commit("setAddress", data);
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
          });
      }
      if (param2 == "REMOVE") {
        store.commit("setLoadingStatus", true);
        ApiService.delete(`/api/address/${param1}?force_delete=${param3}`)
          .then(({ data }) => {
            const index = addressesTableData.value.findIndex(
              (x) => x.id === param1
            );
            store.commit("removeAddress", index);
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const onCancel = () => {
      form.value.id = undefined;
      form.value.office_id = office.value.id;
      form.value.company_id = undefined;
      form.value.entity_id = undefined;
      form.value.contact_id = undefined;
      form.value.address_types = undefined;
      form.value.street = "";
      form.value.number = "";
      form.value.floor_number = "";
      form.value.door = "";
      form.value.location_id = undefined;
      form.value.zip_code = "";
      form.value.latitude = "";
      form.value.longitude = "";
      store.commit("setAEdit", false);
    };

    const onSubmit = () => {
      store.commit("setLoadingStatus", true);
      if (form.value.id === undefined) {
        form.value.office_id = office.value[0].id;
        ApiService.post("/api/address", form.value).then(({ data }) => {
          store.commit("setAddress", data);
          store.commit("setLoadingStatus", false);
        });
      } else {
        ApiService.put(`/api/address/${form.value.id}`, form.value).then(
          ({ data }) => {
            store.commit("setAddress", data);
            const index = addressesTableData.value.findIndex(
              (x) => x.id === form.value.id
            );
            store.commit("removeAddress", index);
            store.commit("setLoadingStatus", false);
          }
        );
      }
      store.commit("setAEdit", false);
    };
    onMounted(() => {
      recently_activities.value.forEach((x) => {
        if (x.id === `office_${route.params.id}`) {
          if (Array.isArray(x.data.addresses)) {
            x.data.addresses.forEach((y) => {
              if (y.id !== undefined) {
                form.value = y;
              }
            });
          } else {
            form.value = x.data.addresses;
          }
        }
      });
    });

    return {
      editAddress,
      submitAddressButton,
      addressesTableData,
      header,
      isLoading,
      tableButtons,
      form,
      office,
      location,
      isStreetRequired,
      onNewClick,
      onCancel,
      onSubmit,
      actionsButton,
    };
  },
};
