
import { Form, Field, ErrorMessage } from "vee-validate";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import CompanySelect from "@/components/catalogs-select/CompanySelect.vue";
import OfficesTab from "@/components/companies/offices/OfficesTab.vue";
import UsersSelect from "@/components/catalogs-select/UsersSelect.vue";
import AddressSection from "@/components/companies/offices/AddressSection.vue";
import CommunicationSection from "@/components/companies/offices/CommunicationSection.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import AccountingPlanSelect from "@/components/catalogs-select/AccountingPlanSelect.vue";
//import Loading from "@/components/Loading.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import Avatar from "@/components/avatar/avatar.vue";
import router from "@/router";

export default defineComponent({
  name: "OfficeCreateView",
  components: {
    Avatar,
    PortsSelect,
    //Loading,
    AccountingPlanSelect,
    Form,
    Field,
    ErrorMessage,
    CompanySelect,
    UsersSelect,
    AddressSection,
    CommunicationSection,
    OfficesTab,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const accountingPlans = ref([]);
    const isFirst = ref(true);
    const viewCheckBox = ref(true);
    const sita_api = ref(document.location.origin);
    // activities log store
    const recently_activities = computed(
      () => store.getters.RecentlyActivities
    );
    const editAddress = computed(() => store.getters.AEdit);
    const editCommunication = computed(() => store.getters.CEdit);
    const addressesTableData = computed(() => store.getters.AddressOffice);
    const communicationsTableData = computed(
      () => store.getters.CommunicationOffice
    );
    const submitButton = ref<HTMLElement | null>(null);
    const logo = ref(undefined) as any;
    const form = ref({}) as any;

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };
    
    const billing_equipments_type_attachment_options = [
      {
        value: 'print_in_report',
        label: t('OfficePrintReport'),
      },
      {
        value: 'additional_document',
        label: t('OfficeAdditionalDocument'),
      },
    ]

    const buildOffice = (data) => {
      const payload = {
        id: data.id,
        company_id: data.company_id,
        name: data.name,
        is_main: data.is_main !== 0,
        logo: data.logo,
        remarks: data.remarks,
        users: data.users,
        addresses: data.addresses,
        communications: data.communications,
        accounting_plan_id: data.accounting_plan_id,
        port_id: data.port_id,
        billing_equipments_type_attachment: data.billing_equipments_type_attachment
      };
      store.commit("setOfficeList", [payload]);
      return payload;
    };

    const onSubmit = (value) => {
      store.commit("setLoadingStatus", true);
      const users = [] as any;
      if (form.value.users) {
        form.value.users.forEach(function (val) {
          users.push(val);
        });
      }
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();
        formData.append("company_id", form.value.company_id);
        formData.append("name", form.value.name);
        if (users.length > 0) {
          formData.append("users", users);
        }
        formData.append(
          "accounting_plan_id",
          form.value.accounting_plan_id + ""
        );
        if (logo.value) {
          formData.append("logo", logo.value);
        }
        if (form.value.remarks && form.value.remarks !== "") {
          formData.append("remarks", form.value.remarks);
        }
        formData.append("port_id", form.value.port_id);
        formData.append(
          "is_main",
          form.value.is_main == "1" || form.value.is_main == true ? "1" : "0"
        );
        formData.append(
          'billing_equipments_type_attachment',
          form.value.billing_equipments_type_attachment
        )

        if (!("id" in route.params)) {
          ApiService.postAttach("/api/offices", formData)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              router.push({ name: "officeUpdate", params: { id: data.id } });
              store.commit("setLoadingStatus", false);
              // router.push("/companies/offices/index");
              store.commit("setOfficeList", []);
              window.history.back();
            })
            .catch(() => {
              store.commit("setLoadingStatus", false);
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        } else {
          formData.append(
            "id",
            form.value.id !== undefined ? form.value.id : ""
          );
          formData.append(
            "addresses",
            store.getters.AddressOffice.map((x) => x.id)
          );
          formData.append(
            "communications",
            store.getters.CommunicationOffice.map((x) => x.id)
          );
          ApiService.postAttach(
            `/api/offices/update/${route.params.id}`,
            formData
          )
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              form.value = buildOffice(data);
              store.commit("setLoadingStatus", false);
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              store.commit("setLoadingStatus", false);
            });
        }
      }
    };

    watch(
      () => form,
      (first, second) => {
        if (first && !isFirst.value) {
          store.commit("addDataActivity", {
            id: `office_${route.params.id}`,
            tag: "office",
            data: first,
            name: first.value.name,
          });
        }
        isFirst.value = false;
      },
      { deep: true }
    );

    const companyHasMainOffice = (company_id) => {
      ApiService.get(`/api/companies/${company_id}`).then(({ data }) => {
        data.offices.forEach((item) => {
          if (item.id != form.value.id && item.is_main == 1) {
            viewCheckBox.value = false;
            form.value.is_main = false;
          }
        });
      });
    };

    const getAccountingPlanByCompany = (company_id) => {
      ApiService.get(`/api/companies/${company_id}`).then(({ data }) => {
        accountingPlans.value = data.accounting_plans;
      });
    };

    watch(
      () => form.value.company_id,
      (first) => {
        if (form.value.company_id) {
          viewCheckBox.value = true;
          companyHasMainOffice(first);
          getAccountingPlanByCompany(first);
        }
      }
    );

    watch(
      () => form.value.id,
      (first) => {
        if (form.value.company_id) {
          viewCheckBox.value = true;
          companyHasMainOffice(form.value.company_id);
        }
      }
    );

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      setCurrentPageBreadcrumbs(
        route.params.id !== undefined ? "officesUpdate" : "officesCreate",
        [{ route: "/companies/offices/index", label: "offices" }]
      );
      if (route.params.id) {
        store.commit("setItem", route.params.id);
        const activity_logs = recently_activities.value.find(
          (value) => value.id === `office_${route.params.id}`
        );
        ApiService.get(`/api/offices/${route.params.id}`)
          .then(({ data }) => {
            let users = [] as any;
            let ap = [] as any;
            data.users.forEach(function (user) {
              users.push(user.id);
            });
            const officeData = {
              id: data.id,
              company_id: data.company_id,
              port_id: data.port_id,
              company: data.company.name,
              name: data.name,
              is_main: data.is_main !== 0,
              logo: data.logo,
              remarks: data.remarks,
              users: users,
              entities: data.entities,
              addresses: data.addresses,
              communications: data.communication,
              accounting_plan_id: data.accounting_plan
                ? data.accounting_plan.id
                : undefined,
              billing_equipments_type_attachment: data.billing_equipments_type_attachment,
            }
            form.value = officeData;
            const date = new Date();
            store.commit("addRecentlyAtivity", {
              tag: "general",
              name: `${t("officesUpdate")} ${form.value.name}`,
              id: `office_${route.params.id}`,
              url: document.location.pathname,
              data: form,
              time: date.toLocaleTimeString(),
              datetime: date.getTime(),
            });

            store.commit("setOfficeList", [
              officeData
            ]);
            store.commit("setOffice", [
              officeData
            ]);

            data.addresses.forEach((x) => {
              store.commit("setAddress", {
                id: x.id,
                company_id: x.company_id,
                office_id: x.office_id,
                entity_id: x.entity_id,
                contact_id: x.contact_id,
                address_types: x.address_types,
                street: x.street,
                number: x.number,
                floor_number: x.floor_number,
                door: x.door,
                location_id: x.location_id,
                location: x.location,
                latitude: x.latitude,
                longitude: x.longitude,
                zip_code: x.zip_code,
              });
            });

            data.communication.forEach((x) => {
              store.commit("setCommunication", {
                id: x.id,
                media_id: x.media_id,
                office_id: x.office_id,
                value: x.value,
                remark: x.remark,
                media_name: x.media.name,
              });
            });
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
            submitButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    });

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: update") !== -1
      );
    });
    onUnmounted(() => {
      store.commit("resetOfficeSelectModule");
      store.commit("setOfficeList", []);
    });

    return {
      form,
      accountingPlans,
      viewCheckBox,
      submitButton,
      addressesTableData,
      communicationsTableData,
      editAddress,
      editCommunication,
      getAccountingPlanByCompany,
      isNameRequired,
      changeImage,
      removeImage,
      onSubmit,
      logo,
      sita_api,
      canUpdate,
      billing_equipments_type_attachment_options
    };
  },
});
