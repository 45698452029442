
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "OfficesTab",

  setup() {
    const store = useStore();
    const office = computed(() => store.getters.OfficeTab);

    const officeUsers = () => {
      return office.value.users.length;
    };
    const officeAddesses = () => {
      return office.value.addresses.length;
    };
    const officeCommunications = () => {
      return office.value.communications.length;
    };
    const officeEntities = () => {
      return office.value.entities.length;
    };

    return {
      office,
      officeUsers,
      officeAddesses,
      officeCommunications,
      officeEntities,
    };
  },
};
